import blog1 from "../assets/images/blog/1.jpg";
import blog3 from "../assets/images/blog/3.jpg";
import client1 from "../assets/images/client/01.jpg";
import client3 from "../assets/images/client/03.jpg";

export const blogData = [
  {
    id: 1,
    image: blog1,
    title: "Inner Symphony Recomposition Show",
    desc: "ISR Show, izleyicilerine içsel bir keşif yolculuğu vaat ediyor. Sanatın derinliklerinden ilham alarak, müziğin büyüleyici ritmiyle bir araya gelen bu gösteri, insanın iç dünyasına ışık tutuyor. Frekansların dansından duygusal yolculuklara, fiziksel ve metafiziksel dünyaların kucaklaştığı bir deneyim sunuyor. ISR Show, sadece bir izleyici değil, bir katılımcı olarak, ruhunuzu, bedeninizi ve zihninizin derinliklerini keşfetmenizi sağlıyor.",
    author: "Calvin Carlo",
    client: client1,
    date: "Sep 13, 2023",
  },
  // {
  //     id:2,
  //     image:blog2,
  //     title:'Lignin and the future circular make sssay form AI support system',
  //     author:'Steven Townsend',
  //     client:client2,
  //     date:'Nov 29, 2023'
  // },
  {
    id: 3,
    image: blog3,
    title: "Inner Symphony Recomposition Podcast",
    desc: "ISR Podcast, zeki düşünürlerin, sanatçıların ve bilim insanlarının dünyasına kapı açıyor. Konuklar, müzik, fizik, matematik ve sanatın eşsiz kesişiminde, ilham verici sohbetler ve mizah dolu anekdotlarla dinleyicilerini etkiliyor. ISR Podcast, bilgiyi eğlenceyle harmanlayarak, dinleyicilerine hem öğretici hem de keyifli bir deneyim sunuyor. Her bölüm, izleyicilerini düşündüren ve güldüren sorularla dolu, eğlenceli bir yolculuğa çıkarıyor.",
    author: "Tiffany Betancourt",
    client: client3,
    date: "Dec 29, 2023",
  },
  // {
  //     id:4,
  //     image:blog4,
  //     title:'Ensuring Your Data Security: Mas.ai is Now SOC 2 Type II Compliant',
  //     author:'Mari Harrington',
  //     client:client4,
  //     date:'March 13, 2023'
  // },
  // {
  //     id:5,
  //     image:blog5,
  //     title:'9 Best AI Translation Software (That You’ll Actually Use for translation)',
  //     author:'Floyd Glasgow',
  //     client:client5,
  //     date:'May 6, 2023'
  // },
  // {
  //     id:6,
  //     image:blog6,
  //     title:'How to choose the right qualitative',
  //     author:'Donna Schultz',
  //     client:client6,
  //     date:'June 19, 2023'
  // },
  // {
  //     id:7,
  //     image:blog7,
  //     title:'Scientists speculate that ours might not be held',
  //     author:'Joshua Morris',
  //     client:client7,
  //     date:'June 20, 2023'
  // },
  // {
  //     id:8,
  //     image:blog8,
  //     title:'The Multiverse is the collection of alternate universes',
  //     author:'Rosaria Vargas',
  //     client:client8,
  //     date:'Aug 31, 2023'
  // },
  // {
  //     id:9,
  //     image:blog9,
  //     title:'That share a universal hierarchy a large variety of these',
  //     author:'Carl Williams',
  //     client:client1,
  //     date:'Sep 1, 2023'
  // },
];

import React, { useState } from "react";

import { MdKeyboardArrowDown } from "../assets/icons/vander";
import { useTranslation } from "react-i18next";

export default function Faq() {
  const { t, i18n } = useTranslation("translation");
  const [activeIndex, setActiveIndex] = useState(1);
  const accordionData = [
    {
      id: 1,
      title: `${t("main.sk1")}`,
      desc: `${t("main.sk1Desc")}`,
    },
    {
      id: 2,
      title: `${t("main.sk2")}`,
      desc: `${t("main.sk2Desc")}`,
    },
    {
      id: 3,
      title: `${t("main.sk3")}`,
      desc: `${t("main.sk3Desc")}`,
    },
    {
      id: 4,
      title: `${t("main.sk4")}`,
      desc: `${t("main.sk4Desc")}`,
    },
    {
      id: 5,
      title: `${t("main.sk5")}`,
      desc: `${t("main.sk5Desc")}`,
    },
    {
      id: 6,
      title: `${t("main.sk6")}`,
      desc: `${t("main.sk6Desc")}`,
    },
    {
      id: 7,
      title: `${t("main.sk7")}`,
      desc: `${t("main.sk7Desc")}`,
    },
    {
      id: 8,
      title: `${t("main.sk8")}`,
      desc: `${t("main.sk8Desc")}`,
    },
    {
      id: 9,
      title: `${t("main.sk9")}`,
      desc: `${t("main.sk9Desc")}`,
    },
  ];
  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
          <div className="lg:col-span-4 md:mb-0 mb-2">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-4xl leading-normal font-maharlika text-center text-red-600 font-bold">
              {t("main.skTitle")}
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto mb-6 text-center text-xl">
              {t("main.skP")}
            </p>

            {/* <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-amber-400 border-gray-100 dark:border-gray-800 hover:border-amber-400 dark:hover:border-amber-400 text-slate-900 dark:text-white hover:text-white rounded-md">Contact Us</Link> */}
          </div>

          <div className="lg:col-span-8 md:mt-0 mt-8">
            {accordionData.map((item, index) => {
              return (
                <div
                  className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                  key={index}
                >
                  <h2
                    className="text-base font-maharlika"
                    id="accordion-collapse-heading-1"
                  >
                    <button
                      type="button"
                      onClick={() => setActiveIndex(item.id)}
                      className={`${
                        activeIndex === item.id
                          ? "bg-gray-50 dark:bg-slate-800 text-amber-400"
                          : ""
                      } flex justify-between items-center p-5 w-full font-medium text-start`}
                    >
                      <span>{item.title}</span>
                      <MdKeyboardArrowDown
                        className={`${
                          activeIndex === item.id ? "rotate-180" : ""
                        } w-4 h-4 shrink-0`}
                      />
                    </button>
                  </h2>
                  <div className={activeIndex === item.id ? "" : "hidden"}>
                    <div className="p-5">
                      <p className="text-slate-400 dark:text-gray-400">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

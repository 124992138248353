import React from "react";
import about1 from "../assets/images/features/program1.jpeg";
import about2 from "../assets/images/features/travel2.jpeg";
import about3 from "../assets/images/features/mgy.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Features({ classlist }) {
  const { t, i18n } = useTranslation("translation");

  return (
    <>
      <div className={classlist}>
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-maharlika text-red-600 font-bold">
            <span className=" font-bold bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text">
              {t("main.advantureTitleFirst")}
            </span>
            <br />
            {t("main.advantureP")}
          </h3>

          <p className="text-slate-400 max-w-2xl mx-auto text-xl">
            {t("main.advanturePP")}
          </p>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-6 gap-6">
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:blur-[80px] after:rounded-full">
              <img
                src={about1}
                className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1"
                alt=""
              />
            </div>
            <div className="p-6">
              <h5 className="text-xl font-maharlika justify-center text-center text-red-600 font-bold">
                Program
              </h5>
              <p className="text-slate-400 mt-3 text-md">
                {t("main.educationTitle")}
              </p>
              <div className="mt-4">
                <Link
                  to="/pricing"
                  className="hover:text-amber-400 font-medium duration-500 text-white"
                >
                  {t("main.fnd")}
                  <i className="mdi mdi-chevron-right text-[20px] align-middle"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:blur-[80px] after:rounded-full">
              <img
                src={about2}
                className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1"
                alt=""
              />
            </div>
            <div className="p-6">
              <h5 className="text-xl font-maharlika justify-center text-center text-red-600 font-bold">
                Inner Symphony Journeys
              </h5>
              <p className="text-slate-400 mt-3 text-md">
                {t("main.educationTitle2")}
              </p>
              <div className="mt-4">
                <Link
                  to="/services"
                  className="hover:text-amber-400 font-medium duration-500 text-white"
                >
                  {t("main.fnd")}
                  <i className="mdi mdi-chevron-right text-[20px] align-middle list-none"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:blur-[80px] after:rounded-full">
              <img
                src={about3}
                className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1"
                alt=""
              />
            </div>
            <div className="p-6">
              <h5 className="text-xl font-maharlika justify-center text-center text-red-600 font-bold">
                MGY Personalized Program
              </h5>
              <p className="text-slate-400 mt-3 text-md">
                {t("main.educationTitle3")}
              </p>
              <div className="mt-4">
                <Link
                  to="/pricing"
                  className="hover:text-amber-400 font-medium duration-500 text-white"
                >
                  {t("main.fnd")}
                  <i className="mdi mdi-chevron-right text-[20px] align-middle"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

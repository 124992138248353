import React from "react";
import { Link } from "react-router-dom";
import "../../node_modules/react-modal-video/css/modal-video.css";
import MGY from "../assets/images/features/mgy.png";

export default function AboutThree() {
  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
        <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800">
            <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
              <img
                src={MGY}
                className="ltr:rounded-tr-lg rtl:rounded-tl-lg"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Mesperanza Gül Yiğit Kimdir?
            </h3>
            <p className="text-slate-400 max-w-xl">
              "Mesperanza Gül Yiğit 1983 yılında İstanbul'da doğdu. Ortaokul
              eğitimini İsviçre’nin Genevre kentindeki Aiglon Collegece’de; lise
              eğitimini ise St. Moritz kentindeki Lyceum Alpinum Zuoz'da
              tamamladı. Lisans eğitimini Saint Louis Üniversitesi Psikolojik
              Danışmanlık Bölümü'nde tamamladıktan sonra aynı kurumda klinik
              psikoloji alanında yüksek lisans derecesi aldı. Süreç içerisinde
              bir çok farklı alanda eğitim ve sertifika almayı sürdürdü. Hipnoz
              eğitimini tamamladıktan sonra 2020 yılında Swiss Academy’den müzik
              terapisi eğitimi aldı."
            </p>

            <div className="mt-4">
              <Link
                to="/aboutus"
                className="hover:text-amber-400 font-medium duration-500"
              >
                Find Out More
                <i className="mdi mdi-chevron-right text-[20px] align-middle"></i>
              </Link>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

import { blogData } from "../data/data";

export default function Blogs() {
  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Inner Symphony Recomposition
          </h3>

          <p className="text-slate-400 max-w-xxl mx-auto">
            ISR Show ve ISR Podcast, müzik tutkunlarının gelişmiş bir duyusal
            yolculuğa çıkmasını sağlar. Burada, müzik sadece duyulmaz, aynı
            zamanda hissedilir. Her parça, bir öykünün parçası gibi,
            duygularınızı bir yolculuğa çıkarır, sizi geçmiş anılarınızı
            hatırlamaya, geleceği hayal etmeye ve şu anın tadını çıkarmaya davet
            eder. Inner Symphony Recomposition, sadece müziği dinlemekle kalmaz,
            aynı zamanda onunla etkileşime geçmeyi ve yeniden keşfetmeyi
            amaçlar. Her bir kompozisyon, sanatçının ruh halini, duygularını ve
            hayallerini yansıtırken, dinleyicileri de kendi iç dünyalarını
            keşfetmeye yönlendirir.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {blogData.slice(0, 3).map((item, index) => {
            return (
              <div
                className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700"
                key={index}
              >
                <img
                  src={item.image}
                  className="rounded-md shadow dark:shadow-gray-700"
                  alt=""
                />
                <div className="pt-4">
                  <div className="flex justify-between items-center">
                    {/* <div className="space-x-1">
                                            <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">AI</Link>
                                            <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Marketing</Link>
                                        </div> */}

                    {/* <span className="flex items-center"><FiClock className="h-4 w-4"/><span className="ms-1 text-slate-400">5 min read</span></span> */}
                  </div>

                  <div className="mt-5 p-3">
                    <Link className="text-lg font-semibold hover:text-amber-400">
                      {item.title}
                    </Link>
                    <p className="text-slate-400 max-w-xl mx-auto ">
                      {/* ISR Show, izleyicilerine içsel bir keşif yolculuğu vaat
                      ediyor. Sanatın derinliklerinden ilham alarak, müziğin
                      büyüleyici ritmiyle bir araya gelen bu gösteri, insanın iç
                      dünyasına ışık tutuyor. Frekansların dansından duygusal
                      yolculuklara, fiziksel ve metafiziksel dünyaların
                      kucaklaştığı bir deneyim sunuyor. ISR Show, sadece bir
                      izleyici değil, bir katılımcı olarak, ruhunuzu, bedeninizi
                      ve zihninizin derinliklerini keşfetmenizi sağlıyor. */}
                      {item.desc}
                    </p>
                  </div>

                  {/* <div className="mt-5 flex justify-between items-center">
                                        <span className="flex items-center">
                                            <img src={item.client} className="h-7 w-7 rounded-full" alt=""/>
                                            <Link to="" className="ms-1 text-slate-400 hover:text-amber-400">{item.author}</Link>
                                        </span>

                                        <span className="flex items-center"><FiCalendar className="h-4 w-4"/><span className="ms-1 text-slate-400">{item.date}</span></span>
                                    </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import tEN from "./translation/en/translation.json";
import tTR from "./translation/tr/translation.json";
import tFR from "./translation/fr/translation.json";

const resources = {
  en: {
    translation: tEN,
  },
  tr: {
    translation: tTR,
  },
  fr: {
    translation: tFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    // fallbackLng: "en",
    debug: true,
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React, { useEffect } from "react";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import NavLight from "../components/navlight";
import { FiCheckCircle } from "../assets/icons/vander";
import kurumsalImg from "../assets/images/features/kurumsal.jpeg";
import AmazingFeatures from "../components/amazingFeatures";
import WhatsAppButton from "../components/WhatsAppButton";
import { useTranslation } from "react-i18next";

export default function Corporate({ classList }) {
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  return (
    <>
      <NavLight />
      <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-bottom bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-bold font-maharlika text-red-600 mb-0">
                {t("main.crpTitle")}
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:pb-24 pb-16">
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="">
              <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-maharlika">
                <span className="text-red-600 font-bold">
                  {t("main.crpTitle2")}
                </span>
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">
                {t("main.crp1")}
                <br />
              </p>
              <ul className="list-none  mt-4">
                <h3 className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-maharlika">
                  {t("main.prac2")}
                </h3>
                <li className="mb-2 text-slate-400 flex text-lg">
                  <FiCheckCircle className="text-amber-400 w-9 me-2" />
                  {t("main.crp2")}
                </li>
                <li className="mb-2 text-slate-400 flex text-lg">
                  <FiCheckCircle className="text-amber-400 w-9  me-2" />
                  {t("main.crp3")}
                </li>
                <li className="mb-2 text-slate-400 flex text-lg">
                  <FiCheckCircle className="text-amber-400 w-9 me-2" />
                  {t("main.crp4")}
                </li>
              </ul>
              {/* <h3 className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-maharlika">
                {t("main.crp5")}
              </h3> */}
              {/* <p className="text-slate-400 max-w-xl text-lg">
                {t("main.crp6")}
                <br />
              </p> */}
              <h3 className="mb-4 md:text-2xl md:leading-normal text-2xl leading-normal font-maharlika">
                {t("main.crp7")}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">
                {t("main.crp8")}
                <br />
              </p>
              {/* <div className="mt-4">
                        <Link to="" className="hover:text-amber-400 font-medium duration-500 inline-flex items-center">Find Out More <MdKeyboardArrowRight className="ms-1 text-[20px]"/></Link>
                    </div> */}
            </div>
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img src={kurumsalImg} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>

        <AmazingFeatures />
      </section>
      <Footer />
      <WhatsAppButton />
      <Switcher />
    </>
  );
}

import React, { useEffect } from "react";
import AboutImg from "../assets/images/about.jpg";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import NavLight from "../components/navlight";
import "../../node_modules/react-modal-video/css/modal-video.css";
import WhatsAppButton from "../components/WhatsAppButton";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);
  return (
    <>
      <NavLight />
      <section className="relative md:py-15 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-bottom bg-cover"></section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:m-auto after:w-96 after:h-96 after:bg-gradient-to-tl after:to-amber-400 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800 lg:me-6">
              <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                <img src={AboutImg} alt="" />
              </div>
            </div>

            <div className="">
              <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl justify-center text-center leading-normal font-maharlika">
                <span className="font-bold text-red-600 text-center justify-center">
                  {t("main.mgyTitle")}
                </span>
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">
                {t("main.mgyP")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton />
      <Switcher />
    </>
  );
}

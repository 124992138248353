import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switcher from "../components/switcher";
import Footer from "../components/footer";
import { blogData } from "../data/data";
import NavLight from "../components/navlight";
import Global from "../assets/images/features/global.png";
import AboutThree from "../components/aboutThree";
import AboutTwo from "../components/aboutTwo";
import WhatsAppButton from "../components/WhatsAppButton";
import { useTranslation } from "react-i18next";

export default function Services() {
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  return (
    <>
      <NavLight />
      <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-bottom bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-bold font-maharlika text-red-600 mb-0">
                Inner Symphony Journeys
              </h5>
            </div>

            {/* <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Mortal.Ai</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">Cases</li>
                    </ul> */}
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative md:mt-10 mt-7">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400  after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800">
              <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                <img
                  src={Global}
                  className="ltr:rounded-tr-lg rtl:rounded-tl-lg"
                  alt=""
                />
              </div>
            </div>
            <div className="">
              <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-maharlika text-red-600 font-bold">
                Inner Symphony Journeys:
                <br />
                {t("main.jrTitle")}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">{t("main.jrP")}</p>
            </div>
          </div>
        </div>
        <AboutTwo />
      </section>
      <Footer />
      <WhatsAppButton />
      <Switcher />
    </>
  );
}

import React from "react";

import aboutImg from "../assets/images/features/2.png";

import { FiCheckCircle } from "../assets/icons/vander";
import { useTranslation } from "react-i18next";

export default function AboutTwo() {
  const { t, i18n } = useTranslation("translation");

  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
          <div className="relative  md:order-2">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img
                src={aboutImg}
                className="ltr:rounded-tr-lg rtl:rounded-tl-lg"
                alt=""
              />
            </div>
          </div>
          <div className="order-2 md:order-1">
            <h4 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-maharlika">
              {" "}
              <span className="text-red-600 font-bold justify-center text-center">
                ISR Rhapsody (WorkShop):
              </span>
              <br />
              <span className=" md:text-4xl text-2xl text-red-600 font-bold">
                {t("main.rhTitle")}
              </span>{" "}
            </h4>
            <p className="text-slate-400 text-lg">{t("main.rh1")}</p>
            <h2 className="mb-4 md:text-3xl md:leading-normal text-xl leading-normal font-maharlika mt-5">
              {t("main.prac6")}
            </h2>
            <ul className="list-none text-slate-400 mt-4 text-lg">
              <li className="mb-2 flex">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                {t("main.rh2")}
              </li>
              <li className="mb-2 flex">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                {t("main.rh3")}
              </li>
              <li className="mb-2 flex">
                <FiCheckCircle className="text-amber-400 h-5 w-9 me-2" />{" "}
                {t("main.rh4")}
              </li>
            </ul>
            <p className="text-slate-400 text-lg">{t("main.rh5")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

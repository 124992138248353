import React from "react";
import aboutImg from "../assets/images/features/1.png";

import { FiCheckCircle } from "../assets/icons/vander";

export default function AboutOne() {
  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
          <div className="relative overflow-hidden rounded-lg border border-amber-400/5  ps-6 pt-6 lg:me-8">
            <img
              src={aboutImg}
              className="ltr:rounded-tl-lg rtl:rounded-tr-lg"
              alt=""
            />
          </div>

          <div className="">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Rezonans ve Titreşim Workshopları</h3>
            <p className="text-slate-400 max-w-xl">
              "Ses dalgalarının maddeler üzerindeki etkisi ve rezonans
              prensipleri hakkında teorik bilgiler sunulur. Katılımcılar, farklı
              müzik aletleri ve ses frekansları ile deneyler yaparlar."
            </p>

            <ul className="list-none text-slate-400 mt-4">
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Ses dalgalarının maddeler üzerindeki etkisi ve rezonans
                prensipleri teorik olarak eğitimler verilecek.
              </li>
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Katılımcılar, çeşitli müzik aletlerinde gerçekleştirecekleri deneylerle kendi müzikal keşiflerine yelken açacaklar.{" "}
              </li>
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Katılımcılara, ses frekanslarının gizemli dünyasında kendi keşif yolculuklarına çıkma fırsatı sunulacak.{" "}
              </li>
            </ul>

            {/* <div className="mt-4">
                        <Link to="" className="hover:text-amber-400 font-medium duration-500 inline-flex items-center">Find Out More <MdKeyboardArrowRight className="ms-1 text-[20px]"/></Link>
                    </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

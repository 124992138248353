import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoDark from "../assets/images/logo-dark.png";
import logoWhite from "../assets/images/logo-white.png";
import logoLight from "../assets/images/logo-light.png";
import IsrLogo from "../assets/images/ISRlogotanıtımı-01.png";
import { useTranslation } from "react-i18next";
import { FcGlobe } from "react-icons/fc";

export default function NavLight() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const { t, i18n } = useTranslation("translation");
  let browserLang = window.navigator.language;

  useEffect(() => {
    if (user.lang === user.lang) {
      i18n.changeLanguage(user.lang);
    }
  }, [i18n]);

  const dillerTablo = [
    { name: "English", code: "en" },
    { name: "Türkçe", code: "tr" },
    { name: "French", code: "fr" },
  ];

  const user = [{ userName: "admin@gmail.com", lang: "tr" }];
  const [dropdown, setDropDown] = useState();
  const dilChange = (dil) => {
    setDropDown("");
    i18n.changeLanguage(dil.code);
  };

  useEffect(() => {
    activateMenu();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  function getClosest(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  }

  function activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  }
  /*********************/
  /*  Clickable manu   */
  /*********************/
  if (document.getElementById("navigation")) {
    var elements = document
      .getElementById("navigation")
      .getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        if (elem.target.getAttribute("href") === "#") {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle("open");
        }
      };
    }
  }

  return (
    <>
      <nav
        id="topnav"
        className={`${scroll ? "nav-sticky" : ""} defaultscroll is-sticky`}
      >
        <div className="container font-maharlika">
          <Link className="logo" to="/">
            <span className="inline-block dark:hidden">
              <img src={IsrLogo} className="h-6 l-dark" alt="" />
              <img src={IsrLogo} className="h-6 l-light" alt="" />
            </span>
            <img src={IsrLogo} className="h-9" alt=""></img>
          </Link>

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                className={`${toggleMenu ? "open" : ""} navbar-toggle`}
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          <div id="navigation" className={`${toggleMenu ? "block" : ""}`}>
            <ul className="navigation-menu nav-light">
              <li className="has-submenu parent-menu-item font-maharlika mt-4">
                <Link to="/">{t("main.home")}</Link>
              </li>

              <li>
                <Link to="/pricing" className="sub-menu-item mt-4">
                  Program
                </Link>
              </li>
              <li>
                <Link to="/corporate" className="sub-menu-item mt-4">
                  ISR Corporate
                </Link>
              </li>
              <li className="has-submenu parent-parent-menu-item mt-4">
                <Link to="/services">Inner Symphony Journeys</Link>
              </li>
              <li>
                <Link to="/aboutus" className="sub-menu-item mt-4">
                  MGY
                </Link>
              </li>

              <li>
                <Link to="/contact" className="sub-menu-item mt-4">
                  {t("main.contact")}
                </Link>
              </li>
              <li>
                <FcGlobe
                  style={{ fontSize: "30" }}
                  className="cursor-pointer mt-5 dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setDropDown("show")}
                  onMouseMove={() => setDropDown("show")}
                />
                <div
                  className={`dropdown-menu ${dropdown}`}
                  aria-labelledby="dropdownMenuButton"
                  aria-expanded="true"
                  onMouseLeave={() => setDropDown("")}
                >
                  {dillerTablo &&
                    dillerTablo.map((dil, index) => (
                      <span
                        key={index}
                        className="dropdown-item cursor-pointer"
                        onClick={() => dilChange(dil)}
                      >
                        {dil.name}
                      </span>
                    ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

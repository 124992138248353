import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { useTranslation } from "react-i18next";
import { FiHexagon, FiPhone, FiMail, FiMapPin } from "../assets/icons/vander";
import WhatsAppButton from "../components/WhatsAppButton";

export default function Contact() {
  const { t, i18n } = useTranslation("translation");

  const handleHaritaLinkClick = () => {
    const googleMapsUrl =
      "https://www.google.com/maps/dir//Etiler,+%C3%87aml%C4%B1k+Yolu+Sk.+Onur+Apt+No:9+D:2,+34353+Be%C5%9Fikta%C5%9F%2F%C4%B0stanbul/@41.0808635,28.9546627,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14cab7e25cfdb671:0x78b66b814aa220bd!2m2!1d29.0370562!2d41.0808888?entry=ttu";

    window.open(googleMapsUrl, "_blank");
  };
  const handleWhatsAppRedirect = () => {
    window.open("https://wa.me/905350850893", "_blank");
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);
  return (
    <>
      <Navbar />
      <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3007.4555242039123!2d29.0344886!3d41.0808928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7e25cfdb671%3A0x78b66b814aa220bd!2sG%C3%BCl%20Yigit%20Consulting!5e0!3m2!1str!2str!4v1706689888062!5m2!1str!2str"
              title="my-map"
              style={{ border: "0" }}
              className="w-full h-[500px]"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <section className="relative lg:py-24 py-16">
        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiPhone />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-maharlika">
                  {t("main.phone")}
                </h5>
                <p className="text-slate-400 mt-3">{t("main.phone2")}</p>

                <div className="mt-5">
                  <Link
                    onClick={handleWhatsAppRedirect}
                    to="#"
                    className="hover:text-amber-400 text-white"
                  >
                    +90 535 085 0893
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMail />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-maharlika">Email</h5>
                <p className="text-slate-400 mt-3">{t("main.mail")}</p>

                <div className="mt-5">
                  <Link
                    to="mailto:info@isrglobal.net"
                    className="hover:text-amber-400 text-white"
                  >
                    info@isrglobal.net
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                  <FiMapPin />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-maharlika">
                  {t("main.location")}
                </h5>
                <p className="text-slate-400 mt-3">
                  Etiler/Beşiktaş <br /> İstanbul
                </p>

                <div className="mt-5">
                  <Link
                    onClick={handleHaritaLinkClick}
                    to="#"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox hover:text-amber-400 text-white"
                  >
                    {t("main.maps")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsAppButton />
      <Switcher />
    </>
  );
}

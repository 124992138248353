import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { RiTelegramLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export default function AmazingFeatures() {
  const { t, i18n } = useTranslation("translation");

  const handleWhatsAppRedirect = () => {
    window.open("https://wa.me/905350850893", "_blank");
  };
  const handleMailClick = () => {
    const mailtoLink = `mailto:${"info@isrglobal.net"}`;
    window.location.href = mailtoLink;
  };
  const redirectToTelegram = () => {
    window.open("https://web.telegram.org/", "_blank");
  };
  // const featureData = [
  //   {
  //     icon: "mdi mdi-flip-horizontal",
  //     title: "Plagiarism checker",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  //   {
  //     icon: "mdi mdi-email-edit-outline",
  //     title: "Content Generator",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  //   {
  //     icon: "mdi mdi-star-outline",
  //     title: "Search Engine Optimization",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  //   {
  //     icon: "mdi mdi-bookmark-outline",
  //     title: "Digital name generator",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  //   {
  //     icon: "mdi mdi-account-check-outline",
  //     title: "Ad Targeting tips",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  //   {
  //     icon: "mdi mdi-comment-outline",
  //     title: "Content Rewriter",
  //     desc: "The phrasal sequence of the is now so that many campaign and benefit",
  //   },
  // ];
  return (
    <>
      <div className="container  md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-3 text-center border-b border-t rounded-xl dark:border-slate-800">
          <h3 className="mb-4 mt-4 md:text-3xl md:leading-normal text-2xl leading-normal font-maharlika text-red-600 font-bold">
            {t("main.titleAmazing")}
          </h3>

          <p className="text-md font-bold">
            {t("main.telP")}
            <span
              onClick={handleWhatsAppRedirect}
              className="typewrite bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text cursor-pointer font-bold"
            >
              {""} 0535 085 0893.
            </span>{" "}
          </p>
          <div className="mt-5 mb-3">
            <button
              className="bg-gradient-to-br from-amber-4000 to-fuchsia-6000 text-white text-xl w-1/2 px-6 py-2 rounded mx-1 my-5"
              onClick={handleWhatsAppRedirect}
            >
              <div className="flex px-2">
                <FaWhatsapp className="px-1 mx-3" size={35} />
                {t("main.wp")}
              </div>
            </button>
            <button
              className="bg-gradient-to-br from-amber-40000 to-fuchsia-60000 text-white text-xl w-1/2 px-4 py-2 rounded mx-1"
              onClick={redirectToTelegram}
            >
              <div className="flex px-2">
                <RiTelegramLine className="px-1 mx-2" size={40} />
                {t("main.tlg")}
              </div>
            </button>
          </div>
        </div>

        {/* <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {featureData.map((item, index) => {
            return (
              <div
                className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg bg-white dark:bg-slate-900"
                key={index}
              >
                <i
                  className={`${item.icon} text-4xl bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text`}
                ></i>

                <div className="content mt-7">
                  <Link
                    to=""
                    className="title h5 text-lg font-medium hover:text-amber-400 duration-500"
                  >
                    Plagiarism checker
                  </Link>
                  <p className="text-slate-400 mt-3">
                    The phrasal sequence of the is now so that many campaign and
                    benefit
                  </p>

                  <div className="mt-5">
                    <Link
                      to=""
                      className="hover:text-amber-400 font-medium duration-500"
                    >
                      Read More{" "}
                      <i className="mdi mdi-arrow-right align-middle"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
}

import React, { useEffect } from "react";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import NavLight from "../components/navlight";
import { FiCheckCircle } from "../assets/icons/vander";
import aboutImg from "../assets/images/features/1.jpeg";
import about2Img from "../assets/images/features/s2.jpeg";
import about3Img from "../assets/images/features/suf1.jpeg";
import about4Img from "../assets/images/features/mgy1.jpeg";
import about5Img from "../assets/images/features/mgy2.jpeg";
import AmazingFeatures from "../components/amazingFeatures";
import WhatsAppButton from "../components/WhatsAppButton";
import { useTranslation } from "react-i18next";

export default function Pricings({ classList }) {
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  return (
    <>
      <NavLight />
      <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-bottom bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-bold font-maharlika text-red-600 mb-0">
                Program
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:pb-24 pb-16">
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5  lg:me-8">
              <img
                src={aboutImg}
                className="rounded-lg sm:justify-center"
                alt=""
              />
            </div>
            <div className="">
              <h3 className="mb-4 md:text-2xl sm:text-md md:leading-normal text-3xl leading-normal font-maharlika text-center">
                <span className="text-red-600 font-bold">
                  {" "}
                  ISR I Practioner Teacher
                </span>
                <br />
                {/* {t("main.pracOnline")} */}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">
                {t("main.prac1")}
              </p>
              <h2 className="mb-4 mt-3 md:text-xl md:leading-normal text-2xl leading-normal font-maharlika">
                {t("main.prac2")}
              </h2>
              <ul className="list-none text-slate-400 mt-4 text-lg">
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac3")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac4")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac5")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac13")}
                </li>
              </ul>
              <h2 className="mb-4 mt-3 md:text-xl md:leading-normal text-xl leading-normal font-maharlika">
                {/* {t("main.prac6")} */}
              </h2>
              {/* <ul className="list-none text-slate-400 mt-4 text-lg">
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac7")}
                </li>
                <li className="mb-2 flex ">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac8")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac9")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac10")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                  {t("main.prac11")}
                </li>
                <li className="mb-2 flex">{t("main.prac12")}</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img src={about2Img} className="rounded-lg" alt="" />
            </div>
            <div className="">
              <h3 className="mb-4 md:text-2xl md:leading-normal text-4xl leading-normal font-maharlika text-center">
                <span className="text-red-600 font-bold">
                  ISR Supervisor Team
                </span>
                <br />
                {/* <span className="text-2xl">{t("main.sprOnline")}</span> */}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">
                {t("main.spr1")}
              </p>
              <ul className="list-none  mt-4 text-lg">
                <h3 className="mb-4 md:text-xl md:leading-normal text-xl leading-normal font-maharlika">
                  {t("main.prac2")}
                </h3>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400  me-2" />
                  {t("main.spr2")}
                </li>
                {/* <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7  me-2" />
                  {t("main.spr3")}
                </li> */}
                {/* <h3 className="mb-4 md:text-xl md:leading-normal text-xl leading-normal font-maharlika">
                  {t("main.prac6")}
                </h3> */}
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.spr4")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.spr5")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.spr6")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.spr7")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.spr8")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img src={about3Img} className="rounded-lg" alt="" />
            </div>

            <div className="">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-maharlika text-center">
                <span className="text-red-600 font-bold">
                  ISR II Sufficiency Op.Con Moto Selfsense Program
                </span>
                <br />
                {/* <span className="text-2xl"> {t("main.sfOnline")}</span> */}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">{t("main.sf1")}</p>
              {/* <h3 className="mb-4 mt-4 md:text-2xl md:leading-normal text-xl leading-normal font-maharlika">
                {t("main.sf2")}
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">{t("main.sf3")}</p> */}
              <h3 className="mb-4 mt-4 md:text-2xl md:leading-normal text-xl leading-normal font-maharlika">
                {t("main.prac2")}
              </h3>
              <ul className="list-none text-slate-400 mt-4 text-lg">
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-9 me-2" />
                  {t("main.sf4")}
                </li>
                <li className="mb-2 flex">
                  <FiCheckCircle className="text-amber-400 h-5 w-6 me-2" />
                  {t("main.sf5")}
                </li>
              </ul>
              <h3 className="mb-4 mt-4 md:text-2xl md:leading-normal text-2xl leading-normal font-maharlika">
                {t("main.sf6")}
              </h3>

              <p className="text-slate-400 max-w-xl text-lg">{t("main.sf7")}</p>
            </div>
          </div>
        </div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img src={about4Img} className="rounded-lg" alt="" />
            </div>
            <div className="">
              <h3 className="mb-4 md:text-4xl md:leading-normal text-2xl leading-normal font-maharlika text-center">
                <span className="text-red-600 font-bold">
                  {t("main.mgTitle")}
                </span>
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">{t("main.mg1")}</p>
              <ul className="list-none  mt-4 text-lg">
                <h3 className="mb-4 md:text-xl md:leading-normal text-xl leading-normal font-maharlika">
                  {t("main.prac2")}
                </h3>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-5 me-2" />
                  {t("main.mg2")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-9  me-2" />
                  {t("main.mg3")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.mg4")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-7 me-2" />
                  {t("main.mg5")}
                </li>
                <li className="mb-2 text-slate-400 flex">
                  <FiCheckCircle className="text-amber-400 w-5 me-2" />
                  {t("main.mg6")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="relative overflow-hidden rounded-lg border border-amber-400/5 lg:me-8">
              <img src={about5Img} className="rounded-lg" alt="" />
            </div>

            <div className="">
              <h3 className="mb-4 md:text-4xl md:leading-normal text-3xl leading-normal font-maharlika text-center">
                <span className="text-red-600 font-bold">{t("main.mg7")}</span>
              </h3>
              <p className="text-slate-400 max-w-xl text-lg">{t("main.mg8")}</p>
            </div>
          </div>
        </div>

        <AmazingFeatures />
      </section>
      <Footer />
      <WhatsAppButton />
      <Switcher />
    </>
  );
}
